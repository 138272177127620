
import { Mixins, Component, Prop } from 'vue-property-decorator';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCalendar, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

library.add(faCalendar, faChevronLeft, faChevronRight);

import moment from 'moment';
import DatePickerMixin from '@/components/form/DatePickerMixin';

@Component({
    components: {
        FontAwesomeIcon,
    },
})
export default class DatePickerFilterComponent extends Mixins(DatePickerMixin) {
    @Prop() readonly filterKey!: string;
    dateFormat = 'DD/MM/YY';

    init() {
        if (this.data.value.indexOf('/') === -1) {
            return;
        }

        const dateCurrent = this.data.value.split('/');
        const momentDate = this.data.value;

        const monthName = moment(momentDate, this.dateFormat).format('MMMM');
        const year = moment(momentDate, this.dateFormat).format('YY');
        const yearFull = moment(momentDate, this.dateFormat).format('YYYY');

        const startOfMonth = moment(momentDate, this.dateFormat).startOf('month').date();
        const endOfMonth = moment(momentDate, this.dateFormat).endOf('month').date();

        const weeks: { [index: number]: { [index: number]: number } } = {};
        let weekIndex = 0;
        let weekValue = null;
        let weekOfYear, dayOfWeek;
        for (let index = startOfMonth; index <= endOfMonth; index++) {
            weekOfYear = moment(`${index}/${dateCurrent[1]}/${dateCurrent[2]}`, this.dateFormat).week();
            dayOfWeek = moment(`${index}/${dateCurrent[1]}/${dateCurrent[2]}`, this.dateFormat).isoWeekday();

            if (weekOfYear !== weekValue) {
                weekValue = weekOfYear;
                weekIndex++;
            }

            if (weeks[weekIndex]) {
                weeks[weekIndex][dayOfWeek] = index;
            } else {
                weeks[weekIndex] = {};
                weeks[weekIndex][dayOfWeek] = index;
            }
        }

        this.month = {
            month: moment(momentDate, this.dateFormat).format('M'),
            year: year,
            name: `${monthName} ${yearFull}`,
            startOfMonth: startOfMonth,
            endOfMonth: endOfMonth,
            weeks: weeks,
            currentDay: dateCurrent[0],
        };
    }

    getMonth(number: number) {
        let month = String(number);
        const yearFull = moment(this.month.year, 'YY').format('YYYY');

        if (parseInt(String(month)) < 10) {
            month = `0${month}`;
        }

        this.month.month = number;
        const momentDate = `01/${month}/${this.month.year}`;
        const monthName = moment(momentDate, this.dateFormat).format('MMMM');
        const startOfMonth = moment(momentDate, this.dateFormat).startOf('month').date();
        const endOfMonth = moment(momentDate, this.dateFormat).endOf('month').date();

        const weeks: { [index: number]: { [index: number]: number } } = {};
        let weekIndex = 0;
        let weekValue = null;
        let weekOfYear, dayOfWeek;
        for (let index = startOfMonth; index <= endOfMonth; index++) {
            weekOfYear = moment(`${index}/${this.month.month}/${this.month.year}`, this.dateFormat).week();
            dayOfWeek = moment(`${index}/${this.month.month}/${this.month.year}`, this.dateFormat).isoWeekday();

            if (weekOfYear !== weekValue) {
                weekValue = weekOfYear;
                weekIndex++;
            }

            if (weeks[weekIndex]) {
                weeks[weekIndex][dayOfWeek] = index;
            } else {
                weeks[weekIndex] = {};
                weeks[weekIndex][dayOfWeek] = index;
            }
        }

        this.month.name = `${monthName} ${yearFull}`;
        this.month.weeks = weeks;
        this.month.startOfMonth = startOfMonth;
        this.month.endOfMonth = endOfMonth;
    }

    toggleVisible() {
        if (this.data.value.indexOf('/') === -1) {
            this.data.value = moment().format(this.dateFormat);
            this.init();

            const date = this.data.value.split('/');
            const newValue = {
                day: date[0],
                month: date[1],
                year: date[2],
            };
            this.$emit('click', { key: this.filterKey, date: newValue });
        }
        if (isNaN(this.month.startOfMonth)) {
            this.init();
        }
        this.visible = !this.visible;
    }

    selectValue(dayParam: number) {
        if (!dayParam) {
            return;
        }
        const day = String(dayParam);

        let month = this.month.month;
        if (parseInt(month) < 10) {
            month = `0${month}`;
        }

        const newValue = {
            day: day,
            month: month,
            year: this.month.year,
        };
        this.visible = false;
        this.$emit('click', { key: this.filterKey, date: newValue });

        const momentDate = `${month}/${day}/${this.month.year}`;
        this.currentValue.day = day;
        this.currentValue.month = moment(momentDate, this.dateFormat).format('MMM');
        this.currentValue.month = this.currentValue.month.replace('.', '');
    }
}
