
import { Mixins, Component } from 'vue-property-decorator';

import InputSuggestionsMixin from './InputSuggestionsMixin';

@Component
export default class InputSuggestionsComponentFilter extends Mixins(InputSuggestionsMixin) {
    showHints(event: FocusEvent) {
        const value = (event.target as HTMLInputElement).value;

        this.$emit('input', { key: this.data.key, value });
        this.isShowHints = true;

        if (!value) {
            this.$emit('click', { key: this.data.key, value: { id: 0, value: '' } });
        }
    }
    updateSearch(event: Event) {
        this.isShowHints = true;
        this.$emit('input', { key: this.data.key, value: (event.target as HTMLInputElement).value });
    }
    selectValue(id: number, value: string) {
        this.$emit('click', { key: this.data.key, value: { id, value } });
        this.isShowHints = false;
    }
}
