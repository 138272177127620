<template>
    <div class="mr-select-default__wrapper">
        <span
            class="mr-input-default__title cd-form-input-label"
            :class="{ 'cd-form-input-label-active': visible || data.value }"
            >{{ data.title }}</span
        >

        <div ref="dropdownSelect" class="mr-select-default-toggle__wrapper" :class="stateClass">
            <div class="mr-select-default-input__wrapper cp-input-radius-50" @click="toggleSelect">
                <span v-if="data.iconClass" class="mr-filter-icon" :class="data.iconClass"></span>
                {{ data.value }}

                <arrow-down />
            </div>

            <div v-if="visible" class="mr-multi-select-default-list__wrapper cp-input-radius-25">
                <div
                    v-for="item in data.list"
                    :key="`mr-select-default-list_${item.id}`"
                    :class="{ selected: item.checked }"
                    class="mr-select-default-list cp-input-radius-50"
                    @click="selectValue(data.key, item.id, item.name)"
                >
                    {{ item.name }}
                    <span class="mr-select-default-item-check"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Component } from 'vue-property-decorator';
import ArrowDown from '@/components/icons/ArrowDown.vue';
import MultiSelectMixin from './MultiSelectMixin';

@Component({
    mixins: [MultiSelectMixin],
    components: {
        ArrowDown,
    },
})
export default class MultiSelectFilter extends MultiSelectMixin {
    selectValue(key, id, value) {
        const newValue = {
            key,
            value: {
                id,
                value,
            },
        };
        this.$emit('click', newValue);

        if (this.data.handler) {
            this.$store.dispatch(this.data.handler, newValue);
        }
    }
}
</script>
