<template>
    <div class="mr-select-default__wrapper">
        <span
            class="mr-input-default__title cd-form-input-label"
            :class="{ 'cd-form-input-label-active': visible || current.value }"
            >{{ data.title }}</span
        >

        <div
            ref="dropdownSelect"
            class="mr-select-default-toggle__wrapper"
            :class="[stateClass, { 'cp-padding__t--no': !data.title }]"
        >
            <div class="mr-select-default-overlay" @click="toggleSelect"></div>
            <div class="mr-select-default-input__wrapper cp-input-radius-50" :class="data.error.class">
                <span v-if="data.iconClass" class="mr-filter-icon" :class="data.iconClass"></span>
                {{ current.value }}

                <slot v-if="data.clearAction">
                    <div v-if="current.value" class="mr-select-default-clear icon-close" @click="clearSelect"></div>
                </slot>

                <arrow-down />
            </div>

            <div v-if="visible" class="mr-select-default-list__wrapper cp-input-radius-25">
                <div
                    v-for="item in items"
                    :key="`mr-select-default-list_${item.id}`"
                    class="mr-select-default-list cp-input-radius-50"
                    @click="selectValue(item.id, item.value)"
                >
                    {{ item.value }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArrowDown from '@/components/icons/ArrowDown.vue';
import SelectMixin from '@/components/select/SelectMixin.js';

export default {
    name: 'SelectCustom',
    components: {
        ArrowDown,
    },
    mixins: [SelectMixin],
    methods: {
        selectValue(id, value) {
            const newValue = {
                id: id,
                value: value,
            };
            this.visible = false;
            this.$emit('click', { key: this.data.key, value: newValue });
        },
        clearSelect() {
            this.$store.dispatch(this.data.clearAction, this.data.key);
            this.$emit('clear');
        },
    },
};
</script>
