<template>
    <div class="mr-fake-checkbox-union__wrapper">
        <checkbox
            v-for="item in data.list"
            :key="item.id"
            :data="item"
            :filter-key="data.key"
            :name="name"
            :action="data.action"
        ></checkbox>
    </div>
</template>

<script>
import Checkbox from '@/components/form/Checkbox.vue';

export default {
    components: {
        Checkbox,
    },
    props: ['data', 'name'],
};
</script>
