<template>
    <div class="mr-radio-switch__wrapper" :class="{ 'required-field-wrapper': data.required }">
        <label
            :for="`${data.name}_${data.list[0].id}`"
            class="mr-radio-switch__option cp-cursor-pointer"
            :class="{ checked: data.list[0].checked }"
        >
            <div class="mr-radio-option-title">{{ data.list[0].name }}</div>
        </label>

        <div class="mr-radio-switch__buttons-wrapper">
            <label class="mr-radio-switch__option cp-cursor-pointer" :class="{ checked: data.list[0].checked }">
                <input
                    :id="`${data.name}_${data.list[0].id}`"
                    type="radio"
                    :name="data.name"
                    :value="0"
                    @change="updateValue"
                />
                <div class="mr-radio-switch__icon icon-check-mark"></div>
            </label>

            <label v-if="visible" class="mr-radio-switch__option mr-radio-switch__option--reset cp-cursor-pointer">
                <input type="radio" :name="data.name" :value="1" @change="updateValue" />
                <div class="mr-radio-switch__icon icon-reload"></div>
            </label>

            <label class="mr-radio-switch__option cp-cursor-pointer" :class="{ checked: data.list[2].checked }">
                <input
                    :id="`${data.name}_${data.list[2].id}`"
                    type="radio"
                    :name="data.name"
                    :value="2"
                    @change="updateValue"
                />
                <div class="mr-radio-switch__icon icon-close"></div>
            </label>
        </div>

        <label
            :for="`${data.name}_${data.list[2].id}`"
            class="mr-radio-switch__option cp-cursor-pointer"
            :class="{ checked: data.list[2].checked }"
        >
            <div class="mr-radio-option-title">{{ data.list[2].name }}</div>
        </label>
    </div>
</template>

<script>
export default {
    props: ['data'],
    data() {
        return {
            visible: false,
        };
    },
    mounted() {
        this.updateVisible();
    },
    methods: {
        async updateValue(e) {
            await this.$store.dispatch(this.data.action, { key: this.data.key, value: e.target.value }, { root: true });
            this.updateVisible();
        },
        updateVisible() {
            this.visible = this.data.list[0].checked === true || this.data.list[2].checked === true;
        },
    },
};
</script>
