
/**
 * Хедер (заголовок, фильтр и т.д.) для основных страниц сайта.
 *
 */

import { Component, Vue, Prop } from 'vue-property-decorator';

import TitlePage from '@/components/Title.vue';
import AddIcon from '@/components/icons/Add.vue';
import FilterModule from '@/admin/store/modules/filter';
import FilterModal from '@/components/FilterDropDown.vue';
import FilterPreview from '@/components/FilterPreview.vue';
import { FilterSettings } from '@/lib/layouts/page/filter.interface';

@Component({
    components: {
        TitlePage,
        FilterModal,
        AddIcon,
        FilterPreview,
    },
})
export default class AppHeader extends Vue {
    @Prop({ required: false }) readonly title?: string;
    @Prop({ required: false }) readonly filterSettings?: FilterSettings;
    @Prop({ required: false }) readonly filterActionReset?: string;
    @Prop({ required: false }) readonly filterHandlers?: any;
    @Prop({ required: false }) readonly filters?: any;
    @Prop({ required: false }) readonly totalItems?: number | string;
    @Prop({ required: false }) readonly isToAddHidden?: boolean;

    toAdd() {
        this.$emit('to-add');
    }

    clearSort() {
        this.$emit('clear-sort');
    }

    closeFilter() {
        FilterModule.closeModal();
    }

    toggleFilter() {
        FilterModule.toggleModal();
    }

    resetFilters() {
        this.$emit('reset-filters');
    }

    updateList() {
        this.$emit('update-list');
    }

    get isShow() {
        return FilterModule.modal.isShow;
    }
}
