<template>
    <div
        class="mr-fake-checkbox__wrapper"
        :class="{
            'mr-fake-checkbox__disabled': data.disabled || disabled === true || disabledAlways === true,
        }"
    >
        <label class="mr-cursor-pointer">
            <input
                :id="`${name}_${data.id}`"
                type="checkbox"
                :value="data.id"
                :checked="data.checked"
                :name="name"
                :disabled="data.disabled === true || disabled === true || disabledAlways === true"
                @change="updateValue(data, $event)"
            />

            <span class="check-mark"></span>
            <span class="mr-fake-checkbox-default-text">{{ data.name }}</span>
        </label>
    </div>
</template>

<script>
export default {
    props: ['data', 'name', 'namespace', 'disabled', 'disabledAlways'],
    methods: {
        updateValue(data, $event) {
            const value = {
                name: this.name,
                id: data.id,
                bool: $event.target.checked,
            };
            this.$emit('click', value);
        },
    },
};
</script>
