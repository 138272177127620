
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import FilterModule from '@/admin/store/modules/filter';

import { FilterPreviewEntryInterface, FilterPreviewItemInterface } from '@/interfaces/filterPreview.interface';

library.add(faTimes);

@Component({
    components: {
        FontAwesomeIcon,
    },
})
export default class FilterPreview extends Vue {
    @Prop({ required: true }) readonly filterPreview?: any;
    @Prop({ required: false }) readonly action?: string;

    filterPreviewsProcessed: any = {};
    hasHiddenPreviews = false;

    get isShow(): boolean {
        return FilterModule.modal.isShow;
    }

    async removeSelected(key: string): Promise<void> {
        if (typeof this.action === 'string') {
            await this.$store.dispatch(this.action, key);
            this.$forceUpdate();
            this.$emit('update-list');
        }
    }

    @Watch('filterPreview', { immediate: true })
    async onFilterPreviewChange(value: any): Promise<void> {
        if (!value) {
            return;
        }

        await this.setSortedPreviews(value);
        this.$nextTick(this.hidePreviews);
    }

    setSortedPreviews(previews: { [key: string]: FilterPreviewItemInterface }): void {
        let values = Object.entries(previews);
        values = values.sort(this.sortCallback);

        this.filterPreviewsProcessed = Object.fromEntries(values);
    }

    hidePreviews(): void {
        const previewWrapper = this.$el as HTMLElement;
        const maxWidth = previewWrapper.offsetWidth - 65;

        const previews = document.getElementsByClassName('mr-filter-preview');
        const previewsArray = Array.from(previews) as HTMLElement[];

        previewsArray.forEach((item) => {
            if (item.offsetLeft + item.offsetWidth > maxWidth) {
                this.hasHiddenPreviews = true;
                item.classList.add('hidden');

                return;
            }
            if (item.classList.contains('hidden')) {
                item.classList.remove('hidden');
            }
            this.hasHiddenPreviews = false;
        });
    }

    sortCallback(a: FilterPreviewEntryInterface, b: FilterPreviewEntryInterface): number {
        return Number(a[1].iterable) - Number(b[1].iterable);
    }
}
