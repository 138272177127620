
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Search from '@/components/Search.vue';
import SelectDefault from '@/components/select/SelectCustomFilter.vue';
import MultiSelect from '@/components/form/MultiSelectCheckbox.vue';
import Checkbox from '@/components/form/CheckboxFilter.vue';
import CheckboxUnion from '@/components/form/CheckboxUnion.vue';
import { directive as clickOutside } from 'v-click-outside';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import DatePicker from '@/components/form/DatePickerFilter.vue';
import RadioSwitch from '@/components/form/RadioSwitch.vue';
import InputSuggestions from '@/components/input/InputSuggestionsFilter.vue';

library.add(faTimes, faTimesCircle);
import FilterModule from '@/admin/store/modules/filter';
import { FilterSettings } from '@/lib/layouts/page/filter.interface';
import Inputmask from 'inputmask';

@Component({
    components: {
        FontAwesomeIcon,
        Search,
        SelectDefault,
        MultiSelect,
        Checkbox,
        DatePicker,
        CheckboxUnion,
        RadioSwitch,
        InputSuggestions,
    },
    directives: { clickOutside },
})
export default class FilterModal extends Vue {
    @Prop({ required: true }) readonly settings!: FilterSettings;
    @Prop({ required: true }) readonly handlers?: any;
    @Prop({ required: false }) readonly filters?: any;
    @Prop({ required: false }) readonly focusKey?: string;

    @Watch('isShow')
    onIsShowChange(val: boolean) {
        if (val) {
            this.setListeners();
            this.focusSearchInput();
            this.$nextTick(this.addMasks);

            return;
        }
        this.resetListeners();
    }

    get isShow() {
        return FilterModule.modal.isShow;
    }

    closeModal() {
        FilterModule.closeModal();
        this.$store.dispatch(this.handlers?.resetState);
    }

    handleSelect(selected: {}, handlerName: string) {
        this.$store.dispatch(handlerName, selected);
    }

    handleCheckbox(
        params: { key: string; checkbox: { name: string; id: number | string; bool: boolean } },
        handlerName: string,
    ) {
        this.$store.dispatch(handlerName, params);
    }

    handleMultiSelect(data: { key: number; selected: { id: number; value: string } }, handlerName: string) {
        this.$store.dispatch(handlerName, data);
    }

    handleDate(
        params: { key: string; eventData: { date: { day: number; month: string; year: string }; key: undefined } },
        action: string,
    ) {
        this.$store.dispatch(action, { key: params.key, date: params.eventData.date }, { root: true });
    }

    clearDate(clearAction: string) {
        this.$store.dispatch(clearAction, {}, { root: true });
    }

    handleInputSuggestionsSearch(params: { key: string; value: string }, handlerName: string) {
        this.$store.dispatch(handlerName, params, { root: true });
    }

    handleInputSuggestionsSelect(params: { key: string; id: number; value: string }, handlerName: string) {
        this.$store.dispatch(handlerName, params, { root: true });
    }

    async executeClear() {
        FilterModule.closeModal();
        this.$emit('reset-filters');
    }

    async executeYes() {
        FilterModule.closeModal();
        this.$emit('update-list');
    }

    setListeners() {
        document.addEventListener('keyup', this.listenerHandler);
    }

    resetListeners() {
        document.removeEventListener('keyup', this.listenerHandler);
    }

    listenerHandler(e: KeyboardEvent) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            this.executeYes();
        }

        if (e.key === 'Esc' || e.keyCode === 27) {
            this.executeClear();
        }
    }

    async focusSearchInput() {
        const searchFilters = Object.values(this.settings.filterModel);

        if (searchFilters.length === 0) return;

        const filterItem = searchFilters.find((el) => el.key === this.focusKey);
        const targetFilter = filterItem || (searchFilters[0] as any);

        let el: any = null;

        if (targetFilter.class) {
            await this.$nextTick();
            el = document.querySelector(`.${targetFilter.class} input`) as HTMLElement;
        }

        if (!el && targetFilter.placeholder) {
            await this.$nextTick();
            el = document.querySelector(
                `.mr-search-input__wrapper input[placeholder="${targetFilter.placeholder}"]`,
            ) as HTMLElement;
        }

        if (el) {
            el.focus();
        }
    }
    addMasks() {
        Object.values(this.settings.filterModel).forEach((item: any) => {
            if (item.mask) {
                const settingsMask = new Inputmask(item.mask);
                const inputs = document.getElementsByClassName(item.class);

                for (const input of Object.values(inputs)) {
                    settingsMask.mask(input.getElementsByTagName('input'));
                }
            }
        });
    }
    clearSelect(data: { clearAction: string; key: string }) {
        this.$store.dispatch(data.clearAction, data.key);
    }
}
