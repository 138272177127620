<template>
    <div>
        <label class="mr-search-input__wrapper cp-input-radius-50">
            <span v-if="data.iconClass" class="mr-filter-icon" :class="data.iconClass"></span>

            <input
                class="mr-search-input__input"
                type="text"
                :value="data.value"
                :placeholder="data.placeholder"
                @input="updateSearch"
                @focusout="updateOnFocus"
            />
        </label>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
        },
    },
    methods: {
        updateSearch(e) {
            this.$store.dispatch(this.data.action, { key: this.data.key, value: e.target.value });
        },
        updateOnFocus(e) {
            this.$store.dispatch(this.data.action, { key: this.data.key, value: e.target.value });
        },
    },
};
</script>
