import { Prop, Component, Vue } from 'vue-property-decorator';
@Component
class MultiSelectMixin extends Vue {
    @Prop() data: any;

    visible = false;
    stateClass = '';
    classOpen = 'open';

    created() {
        document.body.addEventListener('click', this.documentClick);
    }
    updated() {
        document.body.addEventListener('click', this.documentClick);
    }
    destroyed() {
        document.body.addEventListener('click', this.documentClick);
    }
    documentClick(e: Event) {
        const el = this.$refs.dropdownSelect as Node;
        const target = e.target as HTMLElement;

        if (this.visible === true) {
            if (el && el !== target && !el.contains(target)) {
                this.closeState();
            }
        }
    }
    closeState() {
        this.visible = false;
        this.stateClass = '';
    }
    toggleSelect() {
        this.visible = !this.visible;
        this.stateClass = this.stateClass === this.classOpen ? '' : this.classOpen;
    }
    selectValue(id: number | string, value: string) {
        const newValue = {
            id,
            value,
        };
        this.$emit('click', newValue);
    }
    resetValue() {
        this.$store.dispatch(this.data.clearAction);
    }
}

export default MultiSelectMixin;
